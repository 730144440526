<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-dialog
      v-model="editProfileDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <EditProfile v-bind:storage="userData" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changePasswordDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : $vuetify.breakpoint.name == 'sm'
          ? '50vw'
          : $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '25vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-4>
          <v-flex xs12>
            <ChangePassword v-bind:storage="userData" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center v-if="userData">
      <v-flex align-self-center xs12 sm6 text-left>
        <span class="itemHeading"> Profile </span>
      </v-flex>
      <v-flex
        align-self-center
        xs12
        sm6
        text-left
        text-sm-right
        v-if="discipline"
      >
        <v-btn
          :to="'/ViewDA'"
          tile
          color="red"
          class="kumbhMedium"
          dark
          v-if="discipline.length > 0"
        >
          <v-icon dark color="#ffffff">mdi-alert</v-icon>
          <span style="color: #ffffff">Disciplinary Action</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4 v-if="userData">
      <v-flex xs12 sm4 md3 text-left v-if="userData.photo">
        <v-avatar color="#FFF9C4" size="100" v-if="userData.photo">
          <v-img :src="mediaURL + userData.photo">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="#005f32"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </v-flex>
      <v-flex xs12 sm4 md3 text-left v-else>
        <v-avatar color="#FFF9C4" size="100"> </v-avatar>
      </v-flex>
      <v-flex xs12 sm8 md9 text-left v-if="userData.positionid">
        <v-card flat tile>
          <v-layout wrap v-if="userData.positionid.length > 0">
            <v-flex xs12 align-self-center pa-4 text-left>
              <span style="font-size: 18px" class="kumbhMedium"
                >Position(s)</span
              >
            </v-flex>
            <v-flex
              xs12
              sm4
              pa-2
              v-for="(item, i) in userData.positionid"
              :key="i"
              align-self-center
              text-center
            >
              <v-tooltip v-model="item.show" top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    dark
                                    block
                                    depressed
                                    @click="item.show = !item.show"
                                    color="#000"
                                    class="rounded-lg"
                                    style="opacity: 0.8 !important"
                                  >
                                    <span class="kumbhMedium">
                                      {{ item.name }}
                                    </span>
                                  </v-btn> -->

                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    @click="item.show = !item.show"
                    flat
                    tile
                    outlined
                    :color="activePos == item._id ? '#000' : '#000'"
                    :style="
                      activePos == item._id
                        ? 'border:3px dashed  green;opacity:0.8  '
                        : 'opacity:0.7'
                    "
                    class="pa-1 rounded-xl"
                  >
                    <span
                      class="kumbhMedium"
                      :style="
                        activePos == item._id
                          ? 'color:#ffffff;'
                          : 'color:#ffffff'
                      "
                    >
                      <v-icon dark v-if="activePos == item._id" color="green"
                        >mdi-account-check-outline</v-icon
                      >

                      {{ item.name }}
                    </span>
                  </v-card>
                </template>
                <v-card tile color="#000" style="color: #ffffff">
                  <v-layout wrap pa-4 v-if="item.department">
                    <v-flex xs12 align-self-center pa-1 pb-2>
                      <span
                        class="kumbhMedium"
                        style="border-bottom: 1px solid #ccc"
                      >
                        Department
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-1
                      v-if="item.department"
                    >
                      <span class="kumbhMedium" v-if="item.department.deptName">
                        {{ item.department.deptName }}
                      </span>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap pa-2 v-if="item.project">
                    <v-flex xs12 align-self-center pa-1 pb-2>
                      <span
                        class="kumbhMedium"
                        style="border-bottom: 1px solid #ccc"
                      >
                        Project
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-1
                      v-if="item.project"
                    >
                      <span class="kumbhMedium" v-if="item.project.projectName">
                        Project : {{ item.project.projectName }}
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-1
                      v-if="item.project.department"
                    >
                      <span
                        class="kumbhMedium"
                        v-if="item.project.department.deptName"
                      >
                        Department:
                        {{ item.project.department.deptName }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex v-if="userData.name" xs12 sm4 text-left py-2>
            <span class="itemKey">Name</span> <br />
            <span class="itemValue">{{ userData.name }} </span>
          </v-flex>
          <v-flex v-if="userData.employeeId" xs12 sm4 text-left py-2>
            <span class="itemKey">Employee Id</span> <br />
            <span class="itemValue">{{ userData.employeeId }}</span>
          </v-flex>
          <v-flex v-if="userData.grade" xs12 sm4 text-left py-2>
            <span class="itemKey">Grade</span> <br />
            <span class="itemValue">{{ userData.grade }}</span>
          </v-flex>

          <v-flex v-if="userData.employeeStatus" xs12 sm4 text-left py-2>
            <span class="itemKey">Employee Status</span> <br />
            <span class="itemValue">{{ userData.employeeStatus }}</span>
          </v-flex>
          <v-flex xs12 sm4 text-left py-2 v-if="userData.dob">
            <span class="itemKey">DOB</span> <br />
            <span class="itemValue">{{ userData.dob.slice(0, 10) }}</span>
          </v-flex>

          <v-flex xs12 sm4 text-left py-2 v-if="userData.dateofjoining">
            <span class="itemKey">Date of Joining</span> <br />
            <span class="itemValue">{{
              userData.dateofjoining.slice(0, 10)
            }}</span>
          </v-flex>

          <v-flex v-if="userData.email" xs12 sm4 text-left py-2>
            <span class="itemKey">Email</span> <br />
            <span class="itemValue">{{ userData.email }}</span>
          </v-flex>
          <v-flex v-if="userData.phonenumber" xs12 sm4 text-left py-2>
            <span class="itemKey">Phone</span> <br />
            <span class="itemValue">{{ userData.phonenumber }}</span>
          </v-flex>

          <v-flex v-if="userData.country" xs12 sm4 text-left py-2>
            <span class="itemKey">Country</span> <br />
            <span class="itemValue">{{ userData.country }}</span>
          </v-flex>
          <v-flex v-if="userData.locationname" xs12 sm4 text-left py-2>
            <span class="itemKey">Location</span> <br />
            <span class="itemValue">{{ userData.locationname }}</span>
          </v-flex>
          <v-flex v-if="userData.gender" xs12 sm4 text-left py-2>
            <span class="itemKey">Gender</span> <br />
            <span class="itemValue">{{ userData.gender }}</span>
          </v-flex>
          <v-flex xs12 sm4 text-left py-2>
            <span class="itemKey">Account Created</span> <br />
            <span class="itemValue">{{
              formatDate(userData.create_date)
            }}</span>
          </v-flex>
          <v-flex xs12 sm4 text-left py-2>
            <span class="itemKey">Address</span> <br />
            <span class="itemValue">{{ userData.address }}</span>
          </v-flex>
          <v-flex xs12 sm4 text-left py-2>
            <span class="itemKey">Location</span> <br />
            <span class="itemValue">{{ userData.location }}</span>
          </v-flex>
          <v-flex xs12 sm4 text-left py-2>
            <span class="itemKey">PMR Score</span> <br />
            <span class="itemValue">{{ pmr.avgRate }}</span>
          </v-flex>

          <v-flex xs12 sm4 text-left pt-6>
            <v-btn
              @click="$router.push('/SubTeam2?id=' + userData._id)"
              style="font-family: kumbhBold"
              dark
              class="rounded-xl"
              color="#005f32"
            >
              SUBTEAM
            </v-btn>
          </v-flex>

          <v-flex xs12 align-self-center py-2>
            <v-divider></v-divider>
          </v-flex>

          <v-flex v-if="userData.role" xs12 text-left py-2>
            <span class="itemKey">Role </span>
            <br />
            <span class="itemValue">{{ userData.roleName }}</span>
          </v-flex>

          <v-flex
            v-if="userData.administrativeReportingManager"
            xs12
            sm4
            text-left
            py-2
          >
            <span class="itemKey">Administrative Reporting Manager </span>
            <br />
            <span class="itemValue">{{
              userData.administrativeReportingManager.name
            }}</span>
          </v-flex>

          <v-flex v-if="userData.technicalReportingManager" xs12 text-left py-2>
            <span class="itemKey">Technical Reporting Manager </span>
            <br />
            <span class="itemValue">{{
              userData.technicalReportingManager.name
            }}</span>
          </v-flex>

          <v-flex v-if="userData.principalInvigilator" xs12 sm4 text-left py-2>
            <span class="itemKey">Principal Invigilator</span>
            <br />
            <span class="itemValue">{{
              userData.principalInvigilator.name
            }}</span>
          </v-flex>
          <!-- <v-flex xs12 align-self-center py-2>
            <v-divider></v-divider>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="projects.length > 0">
      <v-flex xs12><span class="itemHeading">Projects</span></v-flex>
      <v-flex xs12 align-self-center pa-4>
        <v-simple-table fixed-header class="elevation-1 #F5F5DC">
          <template v-slot:default>
            <thead>
              <tr class="kumbhEBold">
                <th class="text-left">Department</th>
                <th class="text-left">Division</th>
                <th class="text-left">Project</th>
              </tr>
            </thead>
            <tbody class="kumbhRegular">
              <tr v-for="(item, i) in projects" :key="i">
                <td class="text-left">
                  <span>
                    {{ item.departmentId.deptName }}
                  </span>
                </td>
                <td class="text-left">
                  <span>
                    {{ item.division.divisiontName }}
                  </span>
                </td>

                <td class="text-left">
                  <span>
                    {{ item.ProjectId.projectName }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="userData.showcauseNotice">
      <v-flex xs12><span class="itemHeading">Show Cause</span></v-flex>
      <v-flex xs12 align-self-center pa-4>
        <span
          style="font-family: kumbhMedium; font-size: 18px; color: #000000"
          >{{ userData.showcauseReason }}</span
        >
      </v-flex>
      <v-flex xs12 align-self-center pb-5>
        <span style="font-family: kumbhMedium; font-size: 16px; color: #8e8e8e"
          >Letter/Transfer letter
          <v-chip class="px-2 white--text" color="grey"
            ><span v-if="userData.showcauseNotice">
              {{ userData.showcauseNotice }}
              &nbsp;
              <span
                v-if="
                  userData.showcauseNotice.split('.').pop() == 'pdf' ||
                  userData.showcauseNotice.split('.').pop() == 'docx' ||
                  userData.showcauseNotice.split('.').pop() == 'mp4'
                "
              >
                <a target="_blank" :href="mediaURL + userData.showcauseNotice">
                  <v-btn dark rounded x-small> View </v-btn></a
                ></span
              >
              <span v-else>
                <v-btn
                  @click="(doc = userData.showcauseNotice), (dialog = true)"
                  dark
                  rounded
                  x-small
                >
                  View
                </v-btn>
              </span></span
            ></v-chip
          ></span
        >
      </v-flex>
      <v-dialog persistent v-model="dialog" width="70%">
        <v-card>
          <v-toolbar dense dark color="#7CB9E8">
            <v-spacer></v-spacer>
            <v-btn right icon dark @click="(doc = null), (dialog = false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>
          <v-card-text v-if="doc">
            <v-img :src="mediaURL + doc"></v-img>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              color="red"
              text
              @click="(dialog = false), (doc = null)"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm3 xl2 lg3 pr-2>
        <v-btn
          block
          tile
          color="#005f32"
          :ripple="false"
          depressed
          dark
          @click="editProfileDialog = true"
          class="itemValue"
        >
          Edit Profile
        </v-btn>
      </v-flex>

      <v-flex xs12 sm3 lg3 xl2 pr-2>
        <v-btn
          block
          tile
          outlined
          color="#005f32"
          :ripple="false"
          depressed
          @click="changePasswordDialog = true"
          class="itemValue"
        >
          <span style="color: #000000"> Change Password </span>
        </v-btn>
      </v-flex>

    
        <v-flex xs12 sm4 md4 lg3 pr-2>
          <router-link v-if="showUploadButton" to="/YourDocInfo">
            <v-btn
              block
              tile
              color="#005f32"
              :ripple="false"
              depressed
              class="itemValue"
            >
              <span style="color: white">Upload Documents</span>
            </v-btn>
          </router-link>
        </v-flex>
   

        <v-flex xs12 sm4 md4 lg3 pr-2>
  
          <router-link 
            v-if="showUploadButton" 
            :to="{ path: '/careerGraphEmp', query: { employeeId: employeeId } }">
            <v-btn
              block
              tile
              outlined
              color="#005f32"
              :ripple="false"
              depressed
              class="itemValue"
            >
              <span style="color: black">Career Graph</span>
            </v-btn>
          </router-link>
        </v-flex>
        
     


    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import store from "./../../store";
import EditProfile from "./editProfile";
import ChangePassword from "./changePassword";
export default {
  components: {
    EditProfile,
    ChangePassword,
  },
  data() {
    return {
      adminPositionId: "62a2de91ecd959516cd3b0e6", // Admin position ID
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      employeeId:"",
      projects: [],
      pmr:{},
      timeout: 5000,
      msg: null,
      editProfileDialog: false,
      changePasswordDialog: false,
      userData: null,
      activePos: null,
      discipline: [],
      dialog: false,
    };
  },
  beforeMount() {
    this.getDiscipline();
    this.getData();
    this.getPMR();
  },
  computed: {
    showUploadButton() {
      const positionId = localStorage.getItem("positionId");
      return positionId !== this.adminPositionId; // Show button only if not admin
    },
  },
  methods: {
    getDiscipline() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/employee/disciplinaryAction/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: 1,
          limit: 2,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.discipline = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/employee/profile/me",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.userData = response.data.data;
            this.employeeId=response.data.data._id
            this.projects = response.data.projects;
            this.activePos = response.data.position;
          }
        })  
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    getPMR() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/pmr/view",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            
            this.pmr = response.data.result;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    winStepper(windowData) {
      if (windowData.type == "Edit") {
        if (windowData.getData) this.getData();
        this.editProfileDialog = windowData.editProfile;
      } else if (windowData.type == "Password") {
        if (windowData.status) {
          this.msg = "Password Changed";
          this.showSnackBar = true;
        }
        this.changePasswordDialog = windowData.changePassword;
      }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
